 /**
 * @typedef {Object} ServiceError
 * @property {String} type
 * @property {Number} status
 * @property {object} data
 * @property {*} stack
 * @property {String} message
 * @property {Number|Date} timestamp - Unix timestamp
 */

/**
 * @typedef {Object} ServiceResponse
 * @property {Number} status
 * @property {object} data
 * @property {Number|Date} timestamp - Unix timestamp
 */

export default class Service {
  constructor() {
    this._error = {
      type: '',
      status: 0,
      data: null,
      stack: null,
      message: null,
      timestamp: null
    }
    this._response = {
      status: 0,
      data: null,
      timestamp: null
    }
    this._status = ''
  }

  async _requestWrapper(xhrPromise) {
    try {
      const { data, status } = await xhrPromise
      this._response = {
        data, status
      }
      return this.response
    } catch (err) {
      const {stack, message} = err
      if (err.response) {
        const {status, data} = err.response
        const type = status >= 400 && status < 500 ? 'client' : 'server'
        this._setError({type, status, data, stack, message })
      } else if (err.code === 'ECONNABORTED') {
        this._setError({type: 'timeout', stack, message})
      } else {
        this._setError({type: 'code_exception', stack, message})
      }
      throw this.error
    }
  }

  _setError(err) {
    this._status = 'error'
    this._error = Object.assign({}, this._error, { ...err, timestamp: Date.now() })
  }

  _setResponse(res) {
    this._status = 'success'
    this._response = Object.assign({}, this._response, { ...res, timestamp: Date.now() })
  }

  /**
   * @returns {ServiceResponse}
   */
  get response () {
    return Object.assign({}, this._response)
  }

  
  /**
   * @returns {ServiceError}
   */
  get error () {
    return Object.assign({}, this._error)
  }

}