import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @typedef {Object} getRepository
 * @property {String} CreditApplicationId
 * @property {String} OperationType
 * @property {String} employerName
 */

/**
 * @typedef {Object} postRepository
 * @property {String} CreditApplicationId
 * @property {String} File
 */

/**
 * @class
 * @param  {String} creditApplicationId
 */

export class HomeVerification extends Service {
    constructor(creditApplicationId) {
        super()
        this._creditApplicationId = `${creditApplicationId}`
    }

    /**
     * @param  {String} operationType
     */
    setOperationType(operationType) {
        this._operationType = operationType
    }

    /**
     * @param  {String} employerName
     */
    setEmployerName(employerName) {
        this._employerName = employerName
    }

    /**
     * @param  {String} file
     */
    setFile(file) {
        this._file = file
    }


    /**
   * @returns {getRepository}
   */
    get data() {
        return {
            creditApplicationId: `${this._creditApplicationId}`,
            operationType: this._operationType,
        }
    }

    /**
   * @returns {getRepository}
   */
    get dataIngresos() {
        return {
            creditApplicationId: `${this._creditApplicationId}`,
            operationType: this._operationType,
            employerName: this._employerName,
        }
    }

    get dataPost() {
        const formData = new FormData();
        formData.append('File', this._file)
        formData.append('CreditApplicationId', this._creditApplicationId)
        formData.append('OperationType', this._operationType)
        return formData
    }



    /**
     * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
     */
    send() {
        const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'POST',
            url: '/repository',
            data: self.dataPost,
        }))
    }

    /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
    get() {
        const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'GET',
            url: `/verifications/address-virtual?creditApplicationId=${this._creditApplicationId}`,
            // params: self.data,
        }))
    }

    /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
    put() {
        const self = this
        return this._requestWrapper(LandingV4Instance({
            method: 'PUT',
            url: '/repository',
            data: self.data,
        }))
    }
}