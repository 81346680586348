import React from "react"

import SEO from "../components/seo"
import Layout from '../layouts/findep'
import VerificacionDomicilio from '../components/pages/VerificacionDomicilio'

const VerificacionDomicilioTemplate = ({pageContext}) => (
  <Layout companyName={pageContext.company} gradient >
    <SEO title={pageContext.title} />
    <VerificacionDomicilio pageContext={pageContext} /> 
  </Layout>
)

export default VerificacionDomicilioTemplate